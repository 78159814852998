
import { defineComponent, reactive } from "vue";
import TokenRequest from "@/api/requestToken/TokenRequest";

export default defineComponent({
  name: "EmailForm",
  emits: ["submit"],
  setup(props, { emit }) {
    const state = reactive({
      email: "",
    });

    const submitForm = () => {
      const tokenRequest: TokenRequest = {
        email: state.email,
        telephone: "",
      };
      emit("submit", tokenRequest);
    };

    return {
      state,
      submitForm,
    };
  },
});
