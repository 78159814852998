import TokenRequest from "@/api/requestToken/TokenRequest";
import { VUE_APP_REFUNDS_API } from "@/application.config";

export default async function requestToken(
  tokenRequest: TokenRequest
): Promise<boolean> {
  try {
    const endpoint = `${VUE_APP_REFUNDS_API}request-token`;
    const response = await fetch(endpoint, {
      method: "post",
      body: JSON.stringify(tokenRequest),
    });
    return response.ok;
  } catch (e) {
    return false;
  }
}
