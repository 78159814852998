import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import AuthView from "../views/AuthView.vue";
import { nextTick } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "auth",
    component: AuthView,
    meta: {
      title: "Authenticate - Loop Instant Refund",
    },
  },
  {
    path: "/offer/:token",
    name: "offer",
    props: true,
    meta: {
      title: "Offer - Loop Instant Refund",
    },
    component: () =>
      import(/* webpackChunkName: "offer" */ "../views/OfferView.vue"),
  },
  {
    path: "/summary/:token",
    name: "summary",
    props: true,
    meta: {
      title: "Summary - Loop Instant Refund",
    },
    component: () =>
      import(/* webpackChunkName: "summary" */ "../views/SummaryView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to: RouteLocationNormalized) => {
  nextTick(() => {
    document.title = (to.meta.title as string) ?? "Loop Instant Refund";
  });
});

export default router;
